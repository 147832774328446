// Dependencies
import React, { useRef, useState, useMemo, useEffect } from "react"
import { graphql, navigate } from "gatsby"
import { useSpring, animated } from 'react-spring'
import { useDrag } from 'react-use-gesture'
import { useInView } from 'react-intersection-observer'
// Hooks & Helpers
import useIdle from "../hooks/useIdle"
import useIdleScroll from "../hooks/useIdleScroll"
import useRandomPosition from "../hooks/useRandomPosition"
// Wrappers
// ...
// Components
import Image from "../components/Image"
// import { ScreensaverLogo } from "../components/Lockup"
import Note from "../components/Note"
import RichText from "../components/RichText"
import Video from "../components/Video"


const Draggable = ({ className, delay, index, topLayerState, children }) => {

	// Styling...
	const [topLayer, setTopLayer] = topLayerState
	const [z, setZ] = useState(topLayer)
	const bringToTop = () => {
		if (z <= topLayer) {
			setZ(prev => {
				return prev + 1
			})
			setTopLayer(prev => {
				return prev + 1
			})
		}
	}

	const springOptions = {
		config: { mass: 1, tension: 100, friction: 20 },
	}
	const [{ x, y }, api] = useSpring(() => ({ x: 0, y: 0, ...springOptions }))
	const reposition = ({ x, y }) => api.start({ x, y })

	// Drag...
	const dragOptions = {
  	filterTaps: true,
		// useTouch: true,
		// experimental_preventWindowScrollY: true
	}
  const bind = useDrag((dragState) => {
		if (window.matchMedia("(hover: none)").matches) {
			return false
		}
		const { offset: [dragX, dragY], event, first } = dragState
		event.preventDefault()
		if (first) {
			bringToTop()
		}
		return reposition({ x: dragX, y: dragY })
	}, dragOptions)

  return (
		<animated.div className={className} style={{ x, y, zIndex: z }}>
			<DraggableContent delay={delay} bind={bind}>
				{children}
			</DraggableContent>
		</animated.div>
	)
}

const DraggableContent = ({ bind, delay, children }) => {

	const adjust = useRandomPosition(10)
	const start = useRandomPosition(60, 60)
	const [startTransform, setStart] = useState(`translate(${start.x}%, ${start.y}%)`)

	useEffect(() => {
		if (window.matchMedia("(hover: hover)").matches) {
			setStart(`translate(${start.x}%, ${start.y}%)`)
		} else {
			setStart(`translate(0%, 0%)`)
		}
	}, [])

	const { ref, inView } = useInView({
    threshold: 0,
		triggerOnce: true
  });

	const transition = '1.33s transform, 1s opacity'
	const style = inView ? {
		transform: startTransform,
		opacity: 1,
		transition,
		transitionDelay: delay
	} : {
		transform: `translate(${start.x + adjust.x}%, ${start.y + 8}%)`,
		opacity: 0,
		transition,
		transitionDelay: delay
	}

	return (
		<div className="moodboard__story" style={style} {...bind()} ref={ref}>
			{children}
		</div>
	)
}

function useRandomDelay(condition, range) {
	const random = useRef(Math.floor(Math.random() * range))
	return condition ? `${random.current * 0.7}s` : '0s'
}

function useBackgroundColor(color) {
	useEffect(() => {
		document.documentElement.style.setProperty('--background', color || 'var(--offwhite)');
	}, [color])
}

const Story = ({ 
	contentful_id,
	caption,
	chapterSlug, 
	chapterGalleryLink, 
	isHomepageIntro,
	index,
	image, 
	note,
	thumbnailSize, 
	topLayerState,
	video 
}) => {

	// UX
	const delay = isHomepageIntro ? `${index * 0.7}s` : '0s'
	
	// UI
	const handleClick = () => {
		if (chapterGalleryLink) {
			const anchor = !note ? `#story${contentful_id}` : ``
			const to = `/${chapterSlug}/${chapterGalleryLink[0].slug}${anchor}`
			// console.log(chapterGalleryLink)
			navigate(to)
		}
	}

	// Props
	const draggableProps = {
		topLayerState,
		index,
		delay
	}

	return (
		image ? (
			<Draggable className={`moodboard__media ${thumbnailSize || "medium"}`} {...draggableProps}>
				<Image image={image.gatsbyImageData} />
				{caption && (
					<div className="moodboard__caption mt p3">
						<RichText content={caption} />
					</div>
				)}
				{chapterGalleryLink && chapterSlug && <button className="p3 upcase cta" onClick={handleClick}>+ View more</button>}
			</Draggable>
		) : note ? (
			<Draggable className="moodboard__note" {...draggableProps}>
				<Note content={note} onClick={handleClick} chapterGallery={chapterGalleryLink} />
			</Draggable>
		) : video ? (
			<Draggable className={`moodboard__media ${thumbnailSize || "medium"}`} {...draggableProps}>
				<Video src={video.mp4?.file?.url} />
			</Draggable>
		) : null
	)
}

function useScreensaver(shouldAnimate) {
	const isIdle = useIdle({
    timeToIdle: 8000
  });
	useIdleScroll(isIdle && shouldAnimate)
	return isIdle
}

function useShuffled(array) {
	
	function distribute(long, short) {
		const mergeAtEvery = Math.ceil(long.length / short.length)
		const mergedArray = []
		long.forEach((item, index) => {
			mergedArray.push(item)
			if ((index % mergeAtEvery) === mergeAtEvery - 1) {
				const insert = short.shift()
				if (insert) {
					mergedArray.push(insert)
				}
			}
		})
		return mergedArray
	}

  const random = useRef(Math.random())
	
	const shuffled = useMemo(() => {
		const images = array?.filter(item => !item.note).sort(() => random.current - 0.5)
		const notes = array?.filter(item => item.note)
		return array ? notes ? distribute(images, notes) : images : []
	}, [array])
	
	return shuffled
}

const Moodboard = ({ 
	backgroundColor, 
	slug, 
	stories, 
	showCaptions, 
	isHomepageIntro 
}) => {

	// Content
	const randomStories = useShuffled(stories)

	// UX
	const isIdle = false // useScreensaver(!isHomepageIntro)
	
	// Update background color
	useBackgroundColor(backgroundColor)

	// Track the most recent z-index change...
	const topLayerState = useState(1)

  return (
    <div className={`moodboard ${showCaptions && !isIdle ? 'show-captions' : 'hide-captions'}`}>
			{randomStories?.map((story, index) => {
				return (
					<Story 
						chapterSlug={slug} 
						index={index} 
						topLayerState={topLayerState} 
						{...story} 
						isHomepageIntro={isHomepageIntro} 
						key={story.id} 
					/>
				)
			})}
		</div>
  )
}

export default Moodboard

export const ContentfulChapterFragment = graphql`
	fragment ContentfulChapterFragment on ContentfulChapter {
		title
		slug
		chapterNumber
		backgroundColor
		summary {
			text: summary
		}
		shareImage {
			file {
				url
			}
		}
		stories {
			contentful_id
			id
			chapterGalleryLink: chapter___gallery {
				slug
			}
			image {
				gatsbyImageData(
					layout: FULL_WIDTH
				)
			}
			caption {
				raw
			}
			thumbnailSize
			note {
				raw
			}
			video {
				mp4 {
					file {
						url
					}
				}
			}
		}
	}
`