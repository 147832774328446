import { useEffect, useRef } from 'react'
// Helpers
import requestAnimFrame from '../helpers/requestAnimFrame'
// Hooks
import useIdle from '../hooks/useIdle'

function useIdleScroll(isIdle) {

	const timer = useRef()

	// const isIdle = useIdle({
  //   timeToIdle: delay || 5000
  // });

	useEffect(() => {
		function position() {
			return document.documentElement.scrollTop || document.body.parentNode.scrollTop || document.body.scrollTop;
		}

		const increment = 1
		
		const animate = () => {
			window.scrollTo(0, position() + increment)
			timer.current = requestAnimFrame(animate)
		}

		const clear = () => {
			// console.log('clear?')
			window.cancelAnimationFrame(timer.current)
			window.clearTimeout(timer.current)
		}

		if (isIdle) {
			animate()
		} else {
			clear()
		}

		return () => clear()
	}, [isIdle])

	return
}

export default useIdleScroll